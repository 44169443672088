<template>
  <div class="page-loader page-loader-base">
    <div class="blockui">
      <span>Please wait...</span>
      <span><b-spinner variant="primary" label="Please wait..."></b-spinner>
      </span>
    </div>
  </div>

  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: "Loader",
  props: {
    logo: String,
    spinnerClass: String
  }
};
</script>
