<template>
  <!-- begin:: Header -->
  <div id="kt_header" ref="kt_header" class="header" :class="headerClasses">
    <div class="container-fluid d-flex justify-content-between">
      <!-- LOGO -->
      <div class="p-2">
        <img v-if="brandInfo" :src="brandInfo.brandLogo" height="50px"/>
      </div>

      <!-- WIZARD TITLE AND SUBTITLE -->
      <div class="text-center">
        <span class="font-size-h2 font-weight-bold">
          {{ wizardTitle }}
        </span>
        <br />
        <span class="text-primary font-size-h4 font-weight-bold">
          {{ wizardSubtitle }}
        </span>
      </div>

      <!-- FIXED WIDTH DIV TO OFFSET THE LOGO SIZE FROM THE RIGHT SIDE -->
      <div v-if="!userName" class="w-250px"></div>

      <div v-if="userName" class="text-primary font-size-h4 font-weight-bold mt-1">
        Current User: <b-badge variant="primary" class="align-bottom">{{userName}}</b-badge>
      </div>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";

export default {
  name: "KTHeader",
  components: {
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the topbar is enabled
     * @returns {boolean}
     */
    topbarEnabled() {
      return !!this.layoutConfig("header.topbar.self.display");
    },

    brandInfo () {
      return this.$store.state.wizardBrandInfo;
    },

    wizardTitle: function() {
      const state = this.$store.state;

      // Set page title
      if (state.wizardTitle) {
        document.title = state.wizardTitle;
      }

      return state.wizardTitle;
    },

    wizardSubtitle: function() {
      return this.$store.state.wizardSubtitle;
    },

    userName: function () {
      return this.$store.state.user ? this.$store.state.user.name : null;
    }
  }
};
</script>
